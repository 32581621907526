<template>
    <div class="workbench">
         <div class="statistics">
        <iframe style="width:100%;min-height: 800px;" src="http://edusafe.eachvi.com/serverroot/test.html" frameborder="0"></iframe>
    </div>
    </div>

  </template>
  
  <script>
  export default {
    name: "web110",
    mixins: [],
    data() {
      return {};
    },
    created() {
      let base = sessionStorage.getItem("base");
      this.base = JSON.parse(base);
    },
    methods: {
      //点击操作详情
    
    },
  };
  </script>
  <style lang="less" scoped>
  @pad: 20px;
  @borius: 5px;
  .workbench {
    margin: -5px;
  }
  .title {
    font-weight: 400;
    font-size: 18px;
  }
  .message {
    padding: @pad;
    background-color: #ffffff;
  }
  .head {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
   .statistics {
    margin: 20px;
  }
  .particulars_message {
    width: 35%;
  }
  .news {
    border-radius: @borius;
    background: #ffffff;
    margin-bottom: 20px;
  }
  .news_tltie {
    display: flex;
    align-items: center;
    justify-content: space-between;
    i {
      margin-right: 20px;
      font-size: 20px;
      color: #4d576d;
    }
  }
  .news_table {
    padding: 0 20px;
  }
  .task_title {
    div {
      margin-left: 30px;
      color: #475165;
    }
    i {
      color: #3aa0ff;
    }
  }
  .task_num {
    div {
      margin-left: 15px;
      color: #475165;
    }
  }
  .paging {
    padding: 10px 0;
    text-align: center;
  }
  .dropdown-sty {
    width: 300px;
    display: flex;
    justify-content: space-between;
    .p1 {
      flex: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .p2 {
      text-align: right;
      width: 150px;
    }
  }
  </style>
  